import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { CookieService } from 'ngx-cookie';
import { environment } from '@env/environment';
import { TranslatorService } from '@core/translator/translator.service';
import { UserProfile } from '@shared/interfaces/user-profile';
import Swal from 'sweetalert2';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';

@Injectable()
export class UserService {
  private _userProfile$: BehaviorSubject<UserProfile>;
  private _userPermission$: BehaviorSubject<number[]>;

  private get _userProfile(): UserProfile {
    return this.getLoginUserProfile();
  }

  private get _userPermission(): number[] {
    return this.getLoginUserPermission();
  }

  get userProfile$(): Observable<UserProfile> {
    return this._userProfile$;
  }

  get userPermission$(): Observable<number[]> {
    return this._userPermission$;
  }

  constructor(
    private router: Router,
    private translatorService: TranslatorService,
    private cookieService: CookieService,
    private http: HttpClient
  ) {
    this._userProfile$ = new BehaviorSubject<UserProfile>(
      this.getLoginUserProfile()
    );
    this._userPermission$ = new BehaviorSubject<number[]>(
      this.getLoginUserPermission()
    );
    const profile = {
      UserName: window['userName'] || 'User',
      SystemDatetime: new Date(),
      AvatarUrl: 'assets/images/avatar.png'
    };
    // const permission = Permission;
    const userAuth = typeof window['userAuth'] === 'string' ? window['userAuth'].split(',') : ['0'];
    const permission = userAuth.indexOf('1') > -1 || userAuth.indexOf('2') > -1 || userAuth.indexOf('3') > -1 ? [100, 101, 102] : [101, 102];
    this.cookieService.put(environment.loginCookieKey, JSON.stringify(profile));
    this.cookieService.put(
      environment.permissionCookieKey,
      JSON.stringify(permission)
    );
    this._userProfile$.next(this._userProfile);
    this._userPermission$.next(this._userPermission);
  }

  getUserProfile(): Observable<UserProfile> {
    return this.userProfile$;
  }

  getUserPermission(): Observable<number[]> {
    return this.userPermission$;
  }

  /**
   * 登入
   *
   * @param {*} loginInfo
   * @returns {Observable<ApiResponse<UserProfile>>}
   * @memberof UserService
   */
  login(loginInfo: any): Observable<UserProfile> {
    return this.http.post<UserProfile>('api/login/', loginInfo).do(result => {
      // const { UserName, SystemDatetime, AvatarUrl, Permission } = result;
      const profile = {
        UserName: window.localStorage.getItem('userName'),
        SystemDatetime: new Date(),
        AvatarUrl: 'assets/images/avatar.png'
      };
      // const permission = Permission;
      const permission = [100, 101];
      this.cookieService.put(
        environment.loginCookieKey,
        JSON.stringify(profile)
      );
      this.cookieService.put(
        environment.permissionCookieKey,
        JSON.stringify(permission)
      );
      this._userProfile$.next(this._userProfile);
      this._userPermission$.next(this._userPermission);
    });
  }

  /**
   * 登出使用者
   *
   * @memberof UserService
   */
  logout() {
    this.cookieService.remove(environment.loginCookieKey);
    this.cookieService.remove(environment.permissionCookieKey);
    this.http.get('api/logout/').subscribe();
    this.router.navigate(['/', 'login']);
  }

  /**
   * 取得登入使用者的資訊
   *
   * @returns {UserProfile}
   * @memberof UserService
   */
  getLoginUserProfile(): UserProfile {
    const cookie = this.cookieService.get(environment.loginCookieKey);
    if (!cookie) {
      // this.http.get('api/logout/').subscribe();
      // this.router.navigate(['/', 'login']);
    } else {
      return JSON.parse(cookie);
    }
  }

  /**
   * 取得登入使用者的權限
   */
  getLoginUserPermission(): number[] {
    const cookie = this.cookieService.get(environment.permissionCookieKey);
    if (!cookie) {
      // this.http.get('api/logout/').subscribe();
      // this.router.navigate(['/', 'login']);
    } else {
      return JSON.parse(cookie);
    }
  }

  /**
   * 檢查目前是否登入
   *
   * @returns {boolean}
   * @memberof UserService
   */
  isUserLogin(): Observable<any> {
    return this.http
      .get<any>('api/login/')
      .map(res => true)
      .catch(err => Observable.of(false))
      .do(result => {
        if (!result) {
          Swal({
            title: this.translatorService.translate.instant('General.Error'),
            text: this.translatorService.translate.instant(
              'General.Messages.NoAuth'
            ),
            type: 'error'
          });
        }
      });
  }
}
