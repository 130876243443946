import { Injectable } from '@angular/core';
import {
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '@core/user/user.service';
import { MenuList } from '@shared/enum/menu-list.enum';

@Injectable()
export class MenuRouteGuard implements CanActivateChild {
  userPermission$: Observable<number[]>;

  constructor(private userService: UserService, private router: Router) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.userService
      .getUserPermission()
      .map(data => {
        return data.indexOf(MenuList[state.url.split('/')[1]]) > -1;
      })
      .do(result => {
        if (!result) {
          this.router.navigate(['/', 'case']);
        }
      });
  }
}
