import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '@core/user/user.service';

@Injectable()
export class LoginRouteGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return true;
    // return this.userService.isUserLogin().do(islogin => {
    //   if (!islogin) {
    //     this.router.navigate(['/', 'login']);
    //   }
    // });
  }
}
