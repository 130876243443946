import { Injectable } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Injectable()
export class ScreenLockerService {

  @BlockUI() blockUI: NgBlockUI;

  constructor() {}

  show() {
    this.blockUI.start();
  }

  hide() {
    this.blockUI.stop();
  }
}
