import { Component, Input, HostBinding, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { UserService } from '@core/user/user.service';
import { AppService } from '@shared/services/app.service';
import { LayoutService } from '@shared/services/layout.service';
import { UserProfile } from '@shared/interfaces/user-profile';
import 'rxjs/add/operator/shareReplay';

@Component({
  selector: 'app-layout-navbar',
  templateUrl: './layout-navbar.component.html',
  styleUrls: ['./layout-navbar.scss']
})
export class LayoutNavbarComponent implements OnInit {
  userInfo$: Observable<UserProfile>;
  isExpanded = false;
  isRTL: boolean;

  @Input() sidenavToggle = true;

  @HostBinding('class.layout-navbar') private hostClassMain = true;

  constructor(
    private appService: AppService,
    private layoutService: LayoutService,
    private userService: UserService
  ) {
    this.isRTL = appService.isRTL;
  }

  ngOnInit() {
    this.userInfo$ = this.userService.getUserProfile().shareReplay();
  }

  currentBg() {
    return `bg-${this.appService.layoutNavbarBg}`;
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }

  logout() {
    this.userService.logout();
  }
}
