import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { NotifyService } from '@core/notify/notify.service';
import { TranslatorService } from '@core/translator/translator.service';
import { ScreenLockerService } from '@core/screen-locker/screen-locker.service';
import { BaseResult } from './base-result';
import Swal from 'sweetalert2';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/finally';

const textMaping = {
  service_journey_bar: 'ServiceHistory',
  service_journey: 'BulletinCourse',
  risk_index: 'RiskIndicator',
  risk_score: 'RiskScore',
  family_index: 'FamilyIndex',
  children: 'ChildStructure'
};

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  private notifyService: NotifyService;
  private translatorService: TranslatorService;
  constructor(
    private injector: Injector,
    private screenLockerService: ScreenLockerService,
    private router: Router
  ) { }
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      // 請求不處理
      .handle(req)
      // 回傳處理
      .switchMap((response: HttpEvent<any>) => {
        return Observable.create((obs: Observer<any>) => {
          if (
            response instanceof HttpResponse &&
            response.body instanceof Blob &&
            response.body.type === 'application/json'
          ) {
            const reader = new FileReader();
            reader.addEventListener('loadend', () => {
              response = (<HttpResponse<any>>response).clone({
                body: JSON.parse(reader.result as string)
              });
              obs.next(response);
              obs.complete();
            });
            reader.readAsText(response.body);
          } else {
            obs.next(response);
            obs.complete();
          }
        });
      })
      .map((response: HttpEvent<BaseResult>) => {
        if (response instanceof HttpResponse) {
          if (!response.body) {
            return response;
          }
          if (response.body.Status === '200') {
            response = response.clone({ body: response.body.Data });
          } else if (response.body.Status === '403' || response.body.Status === '404') {
            throw {
              ...response.body,
              requestSource: response.url.split('/').splice(response.url.split('/').length - 2, 1)[0]
            };
          } else {
            return response;
          }
        }
        return response;
      })
      .catch(error => {
        this.screenLockerService.hide();
        if (error instanceof HttpErrorResponse) {
          this.handleHttpError(error);
        } else if (error as BaseResult) {
          this.handleError(error);
        }
        return Observable.throw(error);
      });
  }

  /**
   *
   * @param { HttpErrorResponse } error
   */
  handleHttpError(error: HttpErrorResponse) {
    this.translatorService = this.injector.get(TranslatorService);
    this.notifyService = this.injector.get(NotifyService);
    if (!error.status) {
      this.notifyService.notifyHttpError();
      return;
    }
    if (error.status) {
      if (error.status === 200) {
        return;
      }

      if (error.status === 401) {
        this._handle401Error();
      } else if (error.status === 403) {
        this._handle403Error();
      } else {
        this.notifyService.notifyHttpError(error);
      }
    }
  }

  handleError(error: BaseResult) {
    this.translatorService = this.injector.get(TranslatorService);
    this.notifyService = this.injector.get(NotifyService);
    if (error.ErrorKey === '此篩選條件無結果' || error.ErrorKey === '此時間區間內沒有案件發生') {
      this.notifyService.notify(
        'warning',
        this.translatorService.translate.instant(`Case.Board.${textMaping[error.requestSource]}`),
        error.ErrorKey
      );
    }

    // if (!error.Status) {
    //   if (error.ErrorKey === 'Case_Does_Not_Exist') {
    //     Swal(
    //       this.translatorService.translate.instant('General.Error'),
    //       this.translatorService.translate.instant(`General.Messages.${error.ErrorKey}`),
    //       'error'
    //     ).then(result => this.router.navigate(['/', 'case', 'inquiry']));
    //   } else if (error.ErrorKey.startsWith('Login')) {
    //     Swal(
    //       this.translatorService.translate.instant('Login.Failure'),
    //       this.translatorService.translate.instant(`General.Messages.${error.ErrorKey}`),
    //       'error'
    //     );
    //   } else if (error.ErrorKey.startsWith('NotLogin')) {
    //     return;
    //   } else if (error.ErrorKey.startsWith('ApiException')) {
    //     this.notifyService.notify(
    //       'error',
    //       this.translatorService.translate.instant('General.Messages.ApiError'),
    //       error.Message
    //     );
    //   } else {
    //     this.notifyService.notifyWebApiError(error.ErrorKey);
    //   }
    // }
  }

  private _handle401Error() {
    Swal({
      title: this.translatorService.translate.instant('General.Error'),
      text: this.translatorService.translate.instant('General.Messages.NoAuth'),
      type: 'error'
    }).then(result => this.router.navigate(['/', 'login']));
  }

  private _handle403Error() {
    Swal({
      title: this.translatorService.translate.instant('General.Error'),
      text: this.translatorService.translate.instant('General.Messages.NoPermissions'),
      type: 'error'
    });
  }
}
