import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from '@core/translator/translator.service';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor(
    private toastr: ToastrService,
    private translatorService: TranslatorService
  ) { }

  notify(type: string, title: string = '', content: string = '') {
    this.toastr[type](content.replace(/(?:\r\n|\r|\n|,)/g, '<br />'), title);
  }

  notifyWebApiError(errorKey: string) {
    this.toastr.error(
      this.translatorService.translate.instant('WebApiErrors.' + errorKey),
      this.translatorService.translate.instant('General.Error'));
  }

  notifyHttpError(error?: HttpErrorResponse) {
    this.toastr.error(
      (error || error.message) ? error.message : '',
      this.translatorService.translate.instant('General.Messages.SystemError')
    );
  }

  notifySuccess(message: string) {
    this.toastr.success(
      this.translatorService.translate.instant(message),
      this.translatorService.translate.instant('General.Success'));
  }

  broadcast(message: string = '', url: string = '', linkText: string = '') {
    let broadcastMessage = message;
    if (url && linkText) {
      broadcastMessage += `<br/><a href="${url}">${linkText}</a>`;
    }
    this.toastr.warning(broadcastMessage);
  }
}
