import { NgModule } from '@angular/core';
import { RoutesModule } from '@routes/routes.module';
import { LayoutModule } from '@layout/layout.module';
import { CoreModule } from '@core/core.module';
import { AppComponent } from './app.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule.forRoot(),
    LayoutModule,
    RoutesModule,
    BsDatepickerModule.forRoot()
  ],
  providers: [
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {}
