import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from '@routes/routes';
import { UserModule } from '@routes/user/user.module';
import { LoginRouteGuard } from '@routes/shared/login-route.guard';
import { MenuRouteGuard } from '@routes/shared/menu-route.guard';

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    // UserModule
  ],
  providers: [LoginRouteGuard, MenuRouteGuard],
  exports: [
    RouterModule
  ]
})
export class RoutesModule { }
