import { LayoutBlankComponent } from '@layout/layout-blank/layout-blank.component';
import { Layout1Component } from '@layout/layout-1/layout-1.component';

import { LoginComponent } from '@routes/user/login/login.component';
import { LoginRouteGuard } from '@routes/shared/login-route.guard';
import { MenuRouteGuard } from './shared/menu-route.guard';

export const routes = [
  {
    path: '',
    component: Layout1Component,
    // canActivate: [LoginRouteGuard],
    canActivateChild: [MenuRouteGuard],
    children: [
      { path: '', redirectTo: 'case', pathMatch: 'full' },
      { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule' },
      { path: 'case', loadChildren: './case/case.module#CaseModule' },
      { path: 'qa', loadChildren: 'app/routes/qa/qa.module#QaModule' }
    ]
  },
  // {
  //   path: 'login',
  //   component: LayoutBlankComponent,
  //   children: [
  //     { path: '', component: LoginComponent }
  //   ]
  // },
  {
    path: '**', redirectTo: 'case'
  }
];
