import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { BlockUIModule } from 'ng-block-ui';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppHttpInterceptor } from '@core/http/app-http-interceptor';
import { SidenavModule } from '@shared/components/sidenav/sidenav.module';
import { AppService } from '@shared/services/app.service';
import { LayoutService } from '@shared/services/layout.service';
import { ScreenLockerComponent } from '@shared/components/screen-locker/screen-locker.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    BlockUIModule.forRoot({
      template: ScreenLockerComponent
    }),
    NgbModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SidenavModule,
    NgxChartsModule
  ],
  providers: [
    AppService,
    LayoutService,
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true }
  ],
  declarations: [
    ScreenLockerComponent
  ],
  entryComponents: [ ScreenLockerComponent ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    BsDatepickerModule,
    SidenavModule,
    NgxChartsModule,
    BlockUIModule,
    ScreenLockerComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule
    };
  }
}
