import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';
import { environment } from '@env/environment';


@Injectable()
export class TranslatorService {

  defaultLanguage = 'zh_TW';
  availablelangs: { code: string, text: string }[];
  currentLang: string = this.defaultLanguage;

  constructor(
    public translate: TranslateService,
    private cookieService: CookieService
  ) {
    this.availablelangs = [
      { code: 'zh_TW', text: '繁體中文' },
      { code: 'en', text: 'English' }
    ];
    this._setDefaultLanguage();
    this.translate.setDefaultLang(this.defaultLanguage);
    this.useLanguage();
  }

  private _isLanguageAvailable(language: string) {
    return this.availablelangs.filter(lang => lang.code.toUpperCase() === language.toUpperCase()).length > 0;
  }

  private _setDefaultLanguage() {
    const cookieLanguage = this.cookieService.get(environment.languageSettingKey);
    if (cookieLanguage && this._isLanguageAvailable(cookieLanguage)) {
      this.defaultLanguage = cookieLanguage;
    } else {
      let browserLanguage = '';

      if (navigator.languages) {
        browserLanguage = navigator.languages[0];
      } else {
        browserLanguage = navigator.language;
      }
      browserLanguage = browserLanguage.replace('-', '_');
      if (this._isLanguageAvailable(browserLanguage)) {
        this.defaultLanguage = browserLanguage;
      }
    }
  }

  useLanguage(lang: string = this.defaultLanguage) {
    this.cookieService.put(environment.languageSettingKey, lang);
    this.translate.use(lang);
    this.currentLang = lang;
  }

  getAvailableLanguages() {
    return this.availablelangs;
  }

  getCurrentLang() {
    for (const i in this.availablelangs) {
      if (this.availablelangs[i].code === this.currentLang) {
        return this.availablelangs[i].text;
      }
    }
  }

}
