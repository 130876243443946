import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { CookieService, CookieModule } from 'ngx-cookie';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslatorService } from '@core/translator/translator.service';
import { NotifyService } from '@core/notify/notify.service';
import { UserService } from '@core/user/user.service';
import { ScreenLockerService } from './screen-locker/screen-locker.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CookieModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot({
      closeButton: true,
      progressBar: true,
      timeOut: 3000,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ]
})
export class CoreModule {
  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: 'Window', useValue: window },
        TranslateService,
        // MenuService,
        // PagetitleService,
        // ColorsService,
        // SystemService,
        // ConditionService,
        UserService,
        NotifyService,
        // OptionsLoaderService,
        // UsersService,
        CookieService,
        ScreenLockerService,
        // MultiLevelIndeterminateCheckboxService,
        TranslatorService
      ]
    };
  }
}
